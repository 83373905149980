import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useMutation } from 'urql'
import toast from 'react-hot-toast'
import {
  UpdateTalentDocument,
  TalentAvailability,
} from '@upper/graphql/freelancer'
import {
  FormikSelect,
  FormikCheckbox,
  FormikDateField,
  FormikSubmitButton,
} from '@upper/formik'
import { Button, classNames } from '@upper/ui'
import { Dialog, DialogContent, DialogTitle } from './dialog'
import { useTalentStatus } from './with-talent-status'

interface FormValues {
  availability?: string
  nextAvailability?: string
  unavailable: boolean
}

type UpdateAvailabilityDialogProps = {
  onClose: () => void
}

function UpdateAvailabilityDialog({ onClose }: UpdateAvailabilityDialogProps) {
  const { talentId, availability, nextAvailability } = useTalentStatus()
  const [, updateTalent] = useMutation(UpdateTalentDocument)

  const initialValues = {
    availability:
      availability === TalentAvailability.Unavailable || availability === null
        ? ''
        : availability,
    nextAvailability,
    unavailable: availability === TalentAvailability.Unavailable,
  }

  return (
    <Dialog
      open
      onOpenChange={(open) => {
        if (!open) {
          onClose()
        }
      }}
    >
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            availability: Yup.string().when('unavailable', {
              is: (unavailable: boolean) => unavailable === false,
              then: (schema) => schema.required('Required'),
              otherwise: (schema) => schema.optional(),
            }),
            nextAvailability: Yup.string()
              .nullable()
              .when('unavailable', {
                is: (unavailable: boolean) => unavailable === false,
                then: (schema) => schema.required('Required'),
                otherwise: (schema) => schema.optional(),
              }),
          })}
          onSubmit={async (values: FormValues) => {
            const updateTalentResult = await updateTalent({
              id: talentId!,
              data: {
                availability: values.unavailable
                  ? TalentAvailability.Unavailable
                  : (values.availability as TalentAvailability),
                nextAvailability: values.unavailable
                  ? null
                  : values.nextAvailability,
              },
            })

            if (updateTalentResult.error) {
              toast.error(updateTalentResult.error.message)
            } else {
              toast.success('Availability updated')
              onClose()
            }
          }}
        >
          {({ values }) => (
            <Form>
              <DialogTitle className="text-2xl font-bold text-blue md:text-center md:mt-4">
                Update Your Availability
              </DialogTitle>

              <p className="mt-3 text-gray-dark font-medium md:text-center md:mt-10 md:max-w-sm md:mx-auto">
                Please keep your availability up-to-date, so we can match you
                with the right job opportunities!
              </p>

              <div className="grid gap-5 mt-5 md:mt-10 md:grid-cols-2">
                <FormikSelect
                  name="availability"
                  label="Availability"
                  placeholder="Select Availability"
                  disabled={values.unavailable}
                >
                  <option value={TalentAvailability.FullTime}>
                    Available full-time from
                  </option>
                  <option value={TalentAvailability.PartTime}>
                    Available part-time from
                  </option>
                </FormikSelect>

                <div
                  className={classNames(
                    values.unavailable && 'opacity-50 pointer-events-none'
                  )}
                >
                  <FormikDateField
                    name="nextAvailability"
                    label="Date"
                    disablePast
                    containerStyle={{
                      zIndex: '2147483648',
                      pointerEvents: 'auto',
                    }}
                  />
                </div>
              </div>

              <div className="mt-6 md:mt-8">
                <FormikCheckbox name="unavailable">
                  I’m unavailable. 
                </FormikCheckbox>
              </div>

              <div className="flex justify-between mt-10 md:-mx-8 md:mt-14 md:-mb-8 md:px-10 md:py-4 md:bg-gray-lightest md:border-t md:border-gray-light">
                <Button variant="outline" onClick={onClose}>
                  Close
                </Button>

                <FormikSubmitButton>Update</FormikSubmitButton>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateAvailabilityDialog
