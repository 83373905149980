import Link from 'next/link'
import {
  SidebarCollapsableButton,
  SidebarCollapsableButtonProps,
} from './sidebar-collapsable-button'

export function SidebarReferralIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M8.26082 9.82609C10.4219 9.82609 12.1739 8.07416 12.1739 5.91304C12.1739 3.75193 10.4219 2 8.26082 2C6.09971 2 4.34778 3.75193 4.34778 5.91304C4.34778 8.07416 6.09971 9.82609 8.26082 9.82609Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M17 11C18.6569 11 20 9.65685 20 8C20 6.34315 18.6569 5 17 5C15.3431 5 14 6.34315 14 8C14 9.65685 15.3431 11 17 11Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.8"
        d="M11.8181 12.5C10.7796 11.783 9.54042 11.3913 8.26087 11.3913C6.60038 11.3913 5.00791 12.051 3.83377 13.2251C2.65963 14.3992 2 15.9917 2 17.6522H9"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 23C19.7614 23 22 20.7614 22 18C22 15.2386 19.7614 13 17 13C14.2386 13 12 15.2386 12 18C12 20.7614 14.2386 23 17 23Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17 16V20" stroke="currentColor" strokeLinecap="round" />
      <path d="M15 18L19 18" stroke="currentColor" strokeLinecap="round" />
    </svg>
  )
}

export function SidebarReferralButton(
  props: Partial<SidebarCollapsableButtonProps>
) {
  return (
    <Link href={'/referral'} legacyBehavior>
      <SidebarCollapsableButton
        {...props}
        icon={<SidebarReferralIcon />}
        label={'Refer a client'}
      />
    </Link>
  )
}
