import { useAuth } from '@upper/auth'
import {
  RoleKey,
  TalentKycStatus,
  TalentProfileStatus,
  TalentStatus,
} from '@upper/graphql/freelancer'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  HelpIcon,
  HourglassIcon,
  Logo,
  LogoutIcon,
  NavigationDashboardIcon,
  NavigationMyJobsIcon,
  NavigationMyReferralsIcon,
  NavigationOpenPositionsIcon,
  NavigationPerksIcon,
  ShortLogo,
} from '@upper/icons'
import { cn } from '@upper/sapphire/ui'
import { Tooltip, classNames } from '@upper/ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useLocalStorageState } from 'use-local-storage-state'
import { useScrollToTopElement } from '../lib/scroll'
import {
  TALENT_STATUS_BANNER_TITLE_NO_ACCESS,
  TALENT_STATUS_ICONS,
  TALENT_STATUS_INFOS_ACCESS,
  TALENT_STATUS_INFOS_NO_ACCESS,
  TALENT_STATUS_LABELS_ACCESS,
  TALENT_STATUS_LABELS_NO_ACCESS,
  VERIFICATION_STATUS_LABEL,
} from '../lib/talent'
import MobileHeader from './mobile-header'
import { MyProfileButton, MyProfileIcon } from './my-profile-button'
import { NavigationItem } from './navigation-item'
import { SidebarAvailabilityButton } from './sidebar-availability-button'
import { SidebarRateButton } from './sidebar-rate-button'
import { SidebarReferralButton } from './sidebar-referral-button'
import UpdateAvailabilityDialog from './update-availability-dialog'
import UpdateRateDialog from './update-rate-dialog'
import { useTalentStatus } from './with-talent-status'

type MainLayoutProps = {
  children: React.ReactNode
}

export default function MainLayout({ children }: MainLayoutProps) {
  const router = useRouter()
  const scrollToTopEl = useScrollToTopElement()
  const [isUpdateAvailabilityOpen, setIsUpdateAvailabilityOpen] =
    React.useState(router.query.popup === 'availability' ? true : false)
  const [isUpdateRateOpen, setIsUpdateRateOpen] = React.useState(
    router.query.popup === 'rate' ? true : false
  )

  function handleUpdateAvailability() {
    setIsUpdateAvailabilityOpen(true)
  }

  function handleUpdateTalentRate() {
    setIsUpdateRateOpen(true)
  }

  return (
    <div className="bg-gray-lightest min-h-screen md:flex md:h-screen">
      <MobileHeader
        onUpdateAvailability={handleUpdateAvailability}
        onUpdateRate={handleUpdateTalentRate}
      />
      <div className="hidden flex-shrink-0 md:flex">
        <Navigation
          onUpdateAvailability={handleUpdateAvailability}
          onUpdateRate={handleUpdateTalentRate}
        />
      </div>

      <main ref={scrollToTopEl} className="md:flex-1 md:overflow-y-auto">
        <div className="pb-12 md:py-5">
          <div className="mx-auto max-w-md px-8 sm:max-w-2xl md:mx-0 md:max-w-none md:px-0 md:pl-5 md:pr-10">
            {children}
          </div>
        </div>
      </main>

      {isUpdateRateOpen && (
        <UpdateRateDialog
          onClose={() => {
            router.replace(router.pathname, undefined, { shallow: true })
            setIsUpdateRateOpen(false)
          }}
        />
      )}

      {isUpdateAvailabilityOpen && (
        <UpdateAvailabilityDialog
          onClose={() => {
            router.replace(router.pathname, undefined, { shallow: true })
            setIsUpdateAvailabilityOpen(false)
          }}
        />
      )}
    </div>
  )
}

function Navigation({
  onUpdateAvailability,
  onUpdateRate,
}: {
  onUpdateAvailability: () => void
  onUpdateRate: () => void
}) {
  const [isCollapsed, setIsCollapsed] = useLocalStorageState(
    'isNavigationCollapsed',
    true
  )
  const { logout, user, userHasRole } = useAuth()
  const {
    isTalentRejected,
    isTalentActive,
    talentStatus,
    availability,
    hourlyRate,
    nextAvailability,
    hasFreelancerAppAccess,
    verificationStatus,
    profiles,
  } = useTalentStatus()

  const VerifyIcon = TALENT_STATUS_ICONS[talentStatus!]

  const sortedProfiles = profiles?.sort((p) => (p.createdAt > Date() ? 1 : -1))
  const shareableProfile =
    sortedProfiles?.find((p) => p.isFreelancerShareableProfile === true) ??
    sortedProfiles?.find((p) => p.status === TalentProfileStatus.Complete)

  const labelsList = hasFreelancerAppAccess
    ? TALENT_STATUS_LABELS_ACCESS
    : TALENT_STATUS_LABELS_NO_ACCESS

  return (
    <div className="relative flex p-5">
      <button
        type="button"
        className="bg-gray-dark/50 hover:bg-gray-dark focus:ring-blue-light absolute right-1 top-12 inline-flex h-8 w-4 items-center justify-center rounded-l-none rounded-r-md text-white focus:outline-none focus:ring-2"
        onClick={() => {
          setIsCollapsed(!isCollapsed)
        }}
      >
        {isCollapsed ? (
          <ChevronRightIcon className="h-4 w-4" />
        ) : (
          <ChevronLeftIcon className="h-4 w-4" />
        )}
      </button>
      <div
        className={classNames(
          'relative z-10 flex flex-col gap-3 rounded-md text-white shadow-lg transition-all duration-75 ease-in-out',
          isCollapsed ? 'w-[60px]' : '!w-[230px]'
        )}
        style={{
          background:
            'linear-gradient(169.39deg, #0128AB 0.24%, #00EBD3 121.96%)',
        }}
      >
        {/* logo */}
        <div
          className={classNames(
            'flex flex-shrink-0 rounded-t-md bg-transparent py-6',
            isCollapsed ? 'items-center justify-center' : 'px-4'
          )}
        >
          <Link href="/" className={classNames(isCollapsed ? 'p-2' : 'py-2')}>
            {isCollapsed ? (
              <ShortLogo className="h-[30px] w-[30px]" />
            ) : (
              <Logo className="h-auto w-[143px]" />
            )}
          </Link>
        </div>
        {/* main nav */}
        <nav
          role="navigation"
          aria-label="Header main navigation"
          className={classNames(
            'flex flex-col space-y-3 px-2',
            isCollapsed && 'items-center'
          )}
        >
          {!userHasRole(RoleKey.StudioTalent) && (
            <NavigationItem
              icon={<NavigationDashboardIcon />}
              label="Dashboard"
              href="/"
              isNavigationCollapsed={isCollapsed}
            />
          )}
          {!userHasRole(RoleKey.StudioTalent) && (
            <NavigationItem
              icon={<NavigationMyJobsIcon />}
              label="My Jobs"
              href="/my-jobs"
              isNavigationCollapsed={isCollapsed}
            />
          )}
          {!userHasRole(RoleKey.StudioTalent) && (
            <NavigationItem
              icon={<NavigationOpenPositionsIcon />}
              label="Open Positions"
              href="/open-positions"
              isNavigationCollapsed={isCollapsed}
              isLocked={
                (!isTalentActive || isTalentRejected) && !hasFreelancerAppAccess
              }
            />
          )}
          {!userHasRole(RoleKey.StudioTalent) && (
            <NavigationItem
              icon={<NavigationMyReferralsIcon />}
              label="My Referrals"
              href="/my-referrals"
              isNavigationCollapsed={isCollapsed}
              isLocked={
                !hasFreelancerAppAccess && (!isTalentActive || isTalentRejected)
              }
            />
          )}
          {!userHasRole(RoleKey.StudioTalent) && (
            <NavigationItem
              icon={<NavigationPerksIcon />}
              label="Perks"
              href="/perks"
              isNavigationCollapsed={isCollapsed}
              isLocked={
                !hasFreelancerAppAccess && (!isTalentActive || isTalentRejected)
              }
            />
          )}
          <NavigationItem
            icon={<HourglassIcon />}
            label="Timesheet"
            href="/timesheet"
            isNavigationCollapsed={isCollapsed}
            isLocked={
              userHasRole(RoleKey.StudioTalent)
                ? false
                : !hasFreelancerAppAccess &&
                  (!isTalentActive || isTalentRejected)
            }
          />
        </nav>
        {/* secondary nav */}
        <nav
          role="navigation"
          aria-label="Header secondary navigation"
          className={classNames(
            'mx-1 flex-shrink-0 space-y-2 py-3',
            isCollapsed ? 'border-t border-t-white/20 px-1' : 'px-2'
          )}
        >
          {isTalentActive &&
            shareableProfile &&
            (isCollapsed ? (
              <div className="group relative flex flex-col items-center border-t border-white/50 px-1 pb-2 pt-2">
                <div className="bg-gray-dark/50 flex h-10 w-10 items-center justify-center rounded">
                  <MyProfileIcon />
                </div>
                <div className="absolute bottom-1 left-1 hidden w-[200px] rounded bg-[#206E98] group-hover:block">
                  <MyProfileButton profile={shareableProfile} />
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center px-2 pb-2 pt-2">
                <MyProfileButton profile={shareableProfile} />
              </div>
            ))}

          {hasFreelancerAppAccess && (
            <>
              <SidebarReferralButton isCollapsed={isCollapsed} />
              <SidebarRateButton
                isCollapsed={isCollapsed}
                rate={hourlyRate}
                onClick={() => {
                  onUpdateRate()
                }}
              />

              <SidebarAvailabilityButton
                isCollapsed={isCollapsed}
                availability={availability}
                nextAvailability={nextAvailability}
                onClick={() => {
                  onUpdateAvailability()
                }}
              />
            </>
          )}
        </nav>
        <div
          className={classNames(
            'mx-1 mt-auto overflow-auto rounded-lg',
            isCollapsed && 'overflow-visible'
          )}
        >
          {!isTalentActive &&
            !hasFreelancerAppAccess &&
            labelsList[talentStatus!] &&
            (isCollapsed ? (
              <div className="mt-auto flex flex-col items-center border-t border-white/20 pb-2 pt-3">
                <div className="group relative flex">
                  <span className="bg-yellow text-blue inline-flex h-10 w-10 items-center justify-center rounded-md">
                    {VerifyIcon && <VerifyIcon className="h-8 w-8" />}
                  </span>
                  <VerifyCurrentStep
                    verificationStatus={
                      verificationStatus ?? TalentKycStatus.NotStarted
                    }
                    className="lef-0 absolute bottom-0 hidden w-[210px] group-hover:block"
                  />
                </div>
              </div>
            ) : (
              <div>
                <VerifyCurrentStep
                  verificationStatus={
                    verificationStatus ?? TalentKycStatus.NotStarted
                  }
                />
              </div>
            ))}

          {!isTalentActive &&
            hasFreelancerAppAccess &&
            labelsList[talentStatus!] &&
            (isCollapsed ? (
              <div className="flex flex-col items-center border-t border-white/20 pt-3">
                <div className="group relative flex">
                  <span className="bg-yellow text-blue inline-flex h-10 w-10 items-center justify-center rounded-md">
                    <HourglassIcon className="h-8 w-8" />
                  </span>
                  <AccessStep
                    className="lef-0 absolute bottom-0 hidden w-[210px] group-hover:block"
                    hasAppAccess={hasFreelancerAppAccess}
                    status={talentStatus!}
                    verificationStatus={verificationStatus!}
                  />
                </div>
              </div>
            ) : (
              <div>
                <AccessStep
                  hasAppAccess={hasFreelancerAppAccess}
                  status={talentStatus!}
                  verificationStatus={verificationStatus!}
                />
              </div>
            ))}
        </div>
        {/* footer */}
        <nav
          role="navigation"
          aria-label="Header footer navigation"
          className={classNames(
            'mx-0 flex items-center rounded-b-md',
            isCollapsed
              ? 'flex-col space-y-2 border-t border-white/20 pt-3'
              : 'justify-around py-0'
          )}
        >
          <Tooltip
            content="Help"
            contentProps={{
              side: isCollapsed ? 'right' : 'top',
              sideOffset: isCollapsed ? 12 : 0,
            }}
            delayDuration={0}
            triggerProps={{ asChild: true }}
          >
            <a
              href="https://www.notion.so/upperhq/Member-Area-2a7b44cf1f8d4a1681ae8ea48f3e5399s"
              target="_blank"
              rel="noreferrer"
              className="text-gray-darkest/50 hover:text-gray-darkest flex items-center p-3"
              style={{ WebkitAppearance: 'none' }}
            >
              <HelpIcon className="h-4 w-4" />
              <span className="sr-only">Help</span>
            </a>
          </Tooltip>
          <Tooltip
            content="Logout"
            contentProps={{
              side: isCollapsed ? 'right' : 'top',
              sideOffset: isCollapsed ? 12 : 0,
            }}
            delayDuration={0}
            triggerProps={{ asChild: true }}
          >
            <button
              type="button"
              className="text-gray-darkest/50 hover:text-gray-darkest flex items-center p-3"
              style={{ WebkitAppearance: 'none' }}
              onClick={() => {
                logout()
              }}
            >
              <LogoutIcon className="h-4 w-4" />
              <span className="sr-only">Logout</span>
            </button>
          </Tooltip>
        </nav>
      </div>
    </div>
  )
}

function VerifyCurrentStep({
  className,
  verificationStatus = TalentKycStatus.NotStarted,
}: {
  className?: string
  verificationStatus?: TalentKycStatus
}) {
  const { talentStatus } = useTalentStatus()

  const VerifyIcon = TALENT_STATUS_ICONS[talentStatus!]!

  return (
    <div className={classNames('bg-yellow rounded-md p-5', className)}>
      <h4 className="text-gray-darkest text-xl font-semibold leading-6">
        {TALENT_STATUS_BANNER_TITLE_NO_ACCESS[talentStatus!] ??
          'Verify your profile and get full access to the platform'}
      </h4>
      {TALENT_STATUS_INFOS_NO_ACCESS[talentStatus!] && (
        <p
          className="mt-6 text-xs text-black/60"
          dangerouslySetInnerHTML={{
            __html:
              (talentStatus === TalentStatus.Verification
                ? TALENT_STATUS_INFOS_NO_ACCESS?.[TalentStatus.Verification]?.[
                    verificationStatus
                  ]
                : TALENT_STATUS_INFOS_NO_ACCESS[talentStatus!]) ?? '',
          }}
        ></p>
      )}
      <p className="mt-6 text-xs text-black/60">Current step:</p>
      <div className="text-blue mt-1 flex items-center">
        {VerifyIcon && <VerifyIcon className="mr-1 h-10 w-10" />}
        <p className="font-medium">
          {TALENT_STATUS_LABELS_NO_ACCESS[talentStatus!]}
          {talentStatus === TalentStatus.Verification && (
            <span className="text-sm">
              {' ('}
              <span
                className={cn(
                  'text-sm text-black',
                  verificationStatus === TalentKycStatus.Approved &&
                    'text-green-dark',
                  verificationStatus === TalentKycStatus.Declined &&
                    'text-red-dark',
                  (verificationStatus === TalentKycStatus.Submitted ||
                    verificationStatus === TalentKycStatus.Started) &&
                    'text-blue'
                )}
              >
                {VERIFICATION_STATUS_LABEL[verificationStatus]}
              </span>
              {')'}
            </span>
          )}
        </p>
      </div>
    </div>
  )
}

function AccessStep({
  className,
  hasAppAccess,
  status = TalentStatus.Unknown,
  verificationStatus = TalentKycStatus.NotStarted,
}: {
  className?: string
  hasAppAccess: boolean
  status?: TalentStatus
  verificationStatus?: TalentKycStatus
}) {
  return (
    <div className={classNames('bg-yellow rounded-md p-5', className)}>
      <h4 className="text-gray-darkest text-xl font-semibold leading-6">
        {hasAppAccess ? 'Your account is in vetting' : ''}
      </h4>
      <p className="mt-6 text-xs text-black/60">Current step:</p>
      <div className="text-blue mt-1 flex items-center">
        <HourglassIcon className="mr-1 h-10 w-10" />
        <p className="text-sm font-medium">
          {hasAppAccess
            ? TALENT_STATUS_LABELS_ACCESS[status]
            : TALENT_STATUS_LABELS_NO_ACCESS[status]}
          {status === TalentStatus.Verification && (
            <span className="text-sm">
              {' ('}
              <span
                className={cn(
                  'text-sm text-black',
                  verificationStatus === TalentKycStatus.Approved &&
                    'text-green-dark',
                  verificationStatus === TalentKycStatus.Declined &&
                    'text-red-dark',
                  (verificationStatus === TalentKycStatus.Submitted ||
                    verificationStatus === TalentKycStatus.Started) &&
                    'text-blue'
                )}
              >
                {VERIFICATION_STATUS_LABEL[verificationStatus]}
              </span>
              {')'}
            </span>
          )}
        </p>
      </div>
      <p
        className="mt-2 text-xs text-black/60"
        dangerouslySetInnerHTML={{
          __html:
            (hasAppAccess
              ? TALENT_STATUS_INFOS_ACCESS[status]
              : status === TalentStatus.Verification
              ? TALENT_STATUS_INFOS_NO_ACCESS?.[TalentStatus.Verification]?.[
                  verificationStatus
                ]
              : TALENT_STATUS_INFOS_NO_ACCESS[status]) ?? '',
        }}
      ></p>
    </div>
  )
}
