import {
  TalentDataProfileFragment,
  TalentDuplicateProfileDocument,
} from '@upper/graphql/freelancer'
import { useClipboard } from '@upper/hooks'
import { Button, TextField } from '@upper/ui'
import { useCallback, useState } from 'react'
import { useMutation } from 'urql'
import { Dialog, DialogContent } from './dialog'

export function MyProfileIcon() {
  return (
    <svg
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8.88086H25"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6087 4.69055H23.9565C24.2333 4.69055 24.4987 4.80093 24.6944 4.99739C24.8901 5.19386 25 5.46032 25 5.73817V21.4525C25 21.7303 24.8901 21.9968 24.6944 22.1932C24.4987 22.3897 24.2333 22.5001 23.9565 22.5001H2.04348C1.76673 22.5001 1.50132 22.3897 1.30563 22.1932C1.10994 21.9968 1 21.7303 1 21.4525V5.73817C1 5.46032 1.10994 5.19386 1.30563 4.99739C1.50132 4.80093 1.76673 4.69055 2.04348 4.69055H10.3913"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1304 14.119H22.3912"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1304 17.262H19.7825"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0048 19.8808C13.579 19.2208 12.1672 18.741 10.3996 18.0842C9.90078 17.8977 9.98217 16.5903 10.2034 16.3462C10.5552 15.9636 10.8225 15.5106 10.9876 15.017C11.1526 14.5233 11.2119 14.0002 11.1613 13.482C11.1915 13.159 11.1532 12.8333 11.0487 12.5264C10.9443 12.2194 10.7761 11.9382 10.5554 11.7014C10.3347 11.4645 10.0664 11.2774 9.76824 11.1523C9.47012 11.0273 9.14897 10.9672 8.826 10.9761C8.50303 10.9672 8.18188 11.0273 7.88376 11.1523C7.58564 11.2774 7.31734 11.4645 7.09661 11.7014C6.87587 11.9382 6.70772 12.2194 6.60327 12.5264C6.49882 12.8333 6.46045 13.159 6.4907 13.482C6.44014 14.0002 6.49935 14.5233 6.66445 15.017C6.82954 15.5106 7.09678 15.9636 7.44861 16.3462C7.66983 16.5903 7.75122 17.8977 7.25243 18.0842C5.48687 18.741 4.07296 19.2208 3.64722 19.8808"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6087 6.2619C15.6087 6.40083 15.5538 6.53406 15.4559 6.63229C15.3581 6.73053 15.2254 6.78571 15.087 6.78571H10.9131C10.7747 6.78571 10.642 6.73053 10.5442 6.63229C10.4463 6.53406 10.3914 6.40083 10.3914 6.2619V3.11905C10.3914 2.42443 10.6662 1.75827 11.1554 1.2671C11.6447 0.775935 12.3082 0.5 13.0001 0.5C13.6919 0.5 14.3555 0.775935 14.8447 1.2671C15.3339 1.75827 15.6087 2.42443 15.6087 3.11905V6.2619Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

type Props = {
  profile?: TalentDataProfileFragment
}
export function MyProfileButton({ profile }: Props) {
  const [showDialog, setShowDialog] = useState(false)

  const [, duplicateProfile] = useMutation(TalentDuplicateProfileDocument)

  const handleClose = () => setShowDialog(false)

  const copyToClipboard = useClipboard()

  const link = `${process.env.NEXT_PUBLIC_PUBLIC_APP_URL}/resume/${profile?.slug}`

  const handleClick = useCallback(async () => {
    if (profile && !profile?.isFreelancerShareableProfile) {
      await duplicateProfile({ id: profile?.id ?? '' })
      setShowDialog(true)
    } else {
      setShowDialog(true)
    }
  }, [duplicateProfile, profile])

  const handleRequestClick = useCallback(() => {
    if (typeof window !== 'undefined') {
      (window as any)?.Intercom?.('showNewMessage')
    }
  }, [])

  return (
    <>
      <Dialog
        open={showDialog}
        modal
        onOpenChange={(s) => {
          if (!s) handleClose()
        }}
      >
        <DialogContent>
          <div className="pt-6">
            <div className="text-gray-dark space-y-6">
              <h3 className="text-center text-lg">
                As part of your UPPER membership, you get a free professional
                profile created by expert writers. Feel free to use it as your
                resume with your clients, employers and anyone you want.
              </h3>
              <div className="flex gap-3">
                <TextField
                  placeholder={profile?.slug ? '' : 'No profile'}
                  className="w-full"
                  value={profile?.slug ? link : ''}
                  readOnly
                />
                <Button
                  className="whitespace-nowrap"
                  onClick={() => copyToClipboard(link)}
                  disabled={!profile?.slug}
                >
                  Copy link
                </Button>
              </div>
              <caption className="block w-full text-left text-xs">
                As of now editing your profile is not ready. While we work on
                this, you can already use the profile we made for you and if you
                want any adjustment, simply{' '}
                <button
                  type="button"
                  className="text-blue hover:underline"
                  onClick={handleRequestClick}
                >
                  send us a request
                </button>
                .
              </caption>
            </div>
            <footer className="bg-yellow text-gray-darkest -mb-8 -ml-8 -mr-8 mt-8 px-8 py-4 text-sm">
              <strong>Important notice:</strong> This profile is not publicly
              discoverable by search engines and only the people who have the
              unique link can access its contents.
            </footer>
          </div>
        </DialogContent>
      </Dialog>
      <button
        type="button"
        className="bg-gray-darkest/30 flex h-12 w-full items-center gap-3 rounded px-4"
        onClick={handleClick}
      >
        <MyProfileIcon />
        <span className="text-sm font-medium">My profile</span>
      </button>
    </>
  )
}
