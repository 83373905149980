import * as React from 'react'

export function OnboardingIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.668 24.667H2.001a1.334 1.334 0 01-1.333-1.333V2A1.333 1.333 0 012.001.667h20A1.333 1.333 0 0123.335 2v13.334M20.932 29.733l-5.6 1.6 1.6-5.6 9.572-9.572a2.831 2.831 0 014 0 2.83 2.83 0 010 4l-9.572 9.572zM25.305 17.361l4 4M16.934 25.733l4 4M5.332 6h9.333M5.332 10h12M5.332 14h12"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.332 19.488l1.001-1.001a1.333 1.333 0 012.236.62l.096.381 1.091-.726a1.334 1.334 0 011.683.166l.56.56h2.666"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function InviteFriendIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      width={60}
      height={60}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M41.75 36.5a1.25 1.25 0 01-1.224 1H22.45a1.25 1.25 0 01-1.224-1.5l2.604-12.5a1.25 1.25 0 011.224-1h18.071A1.25 1.25 0 0144.35 24L41.75 36.5z"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.284 22.765l6.396 6.889a2.5 2.5 0 003.266.346l10.119-7.083M15.625 23.75h5M15.625 27.5h3.75M15.625 31.25h2.5"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function FriendVettedIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      width={60}
      height={60}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.25 44.75a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.593 35.07l-3.632 4.842a.94.94 0 01-1.413.1l-1.876-1.874M29.75 26.625a5.313 5.313 0 100-10.625 5.313 5.313 0 000 10.625zM31 37.25H21a8.75 8.75 0 0113.479-7.362"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function MoneyBagIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      width={60}
      height={60}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.625 22.913h8.75M32.5 22.913a.637.637 0 00.625-.55 16.874 16.874 0 011.5-4.713.588.588 0 00-.087-.712.6.6 0 00-.7-.15l-2.626 1.125-.637-1.663a.612.612 0 00-1.15 0l-.675 1.663-2.625-1.125a.6.6 0 00-.7.15.587.587 0 00-.088.712 16.877 16.877 0 011.5 4.713.637.637 0 00.625.55M34.063 29.163h-1.25a5 5 0 100 10h1.25M25.938 32.913h6.25M25.938 35.413h5"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5 22.913s8.75 6.875 8.75 12.887c0 6.25-5 8.363-11.25 8.363s-11.25-2.15-11.25-8.363c0-6.012 8.75-12.887 8.75-12.887"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
