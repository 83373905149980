import {
  FormikCheckbox,
  FormikSubmitButton,
  FormikTextarea,
  FormikTextField,
} from '@upper/formik'
import { UpdateTalentDocument } from '@upper/graphql/freelancer'
import { Button } from '@upper/ui'
import { Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import { Dialog, DialogContent, DialogTitle } from './dialog'
import { useTalentStatus } from './with-talent-status'

interface FormValues {
  hourlyRate: string
  minimumHourlyRate: string
  interestedInEmployment: boolean
  interestedInFreelance: boolean
  employmentYearlySalary: string
  rateNotes: string
}

type UpdateAvailabilityDialogProps = {
  onClose: () => void
}

function UpdateRateDialog({ onClose }: UpdateAvailabilityDialogProps) {
  const {
    talentId,
    hourlyRate,
    minimumHourlyRate,
    interestedInEmployment,
    interestedInFreelance,
    employmentYearlySalary,
    rateNotes,
  } = useTalentStatus()
  const [, updateTalent] = useMutation(UpdateTalentDocument)

  const initialValues: FormValues = {
    hourlyRate: hourlyRate ? String(hourlyRate) : '',
    minimumHourlyRate: minimumHourlyRate ? String(minimumHourlyRate) : '',
    interestedInEmployment: interestedInEmployment ?? false,
    interestedInFreelance: interestedInFreelance ?? true,
    employmentYearlySalary: employmentYearlySalary
      ? String(employmentYearlySalary)
      : '',
    rateNotes: rateNotes ?? '',
  }

  return (
    <Dialog
      open
      onOpenChange={(open) => {
        if (!open) {
          onClose()
        }
      }}
    >
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            hourlyRate: Yup.number().required('Required'),
          })}
          onSubmit={async (values: FormValues) => {
            console.log(values)

            const updateTalentResult = await updateTalent({
              id: talentId!,
              data: {
                ...values,
                hourlyRate: values.hourlyRate
                  ? parseFloat(values.hourlyRate)
                  : null,
                minimumHourlyRate: values.minimumHourlyRate
                  ? parseFloat(values.minimumHourlyRate)
                  : null,
                employmentYearlySalary: values.employmentYearlySalary
                  ? parseFloat(values.employmentYearlySalary)
                  : null,
              },
            })

            if (updateTalentResult.error) {
              toast.error(updateTalentResult.error.message)
            } else {
              toast.success('Your rates are updated')
              onClose()
            }
          }}
        >
          {({ values }) => (
            <Form>
              <DialogTitle className="text-2xl font-bold text-blue md:text-center md:mt-4">
                Update your rate info
              </DialogTitle>
              {/* 
              <p className="mt-3 text-gray-dark font-medium md:text-center md:mt-10 md:max-w-sm md:mx-auto">
                Please keep your hourly rate up-to-date!
              </p> */}
              <div className="mt-5 md:mt-10 space-y-5">
                <FormikCheckbox name="interestedInFreelance">
                  {`I'm interested in freelancer jobs`}
                </FormikCheckbox>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                  <FormikTextField
                    name="hourlyRate"
                    label="Desired hourly rate"
                    prefix="€"
                    suffix="/h"
                    placeholder="ex: 30"
                    required
                  />
                  <FormikTextField
                    name="minimumHourlyRate"
                    label="Minimum hourly rate"
                    prefix="€"
                    suffix="/h"
                  />
                </div>
                <FormikTextarea
                  name="rateNotes"
                  label="Anything we should know about your rate?"
                  placeholder="Are you flexible? Does it vary for on-site vs. remote work?
                  Is there maybe a range?"
                  helpText="please be realistic about your desired rate so we can find you a project as soon as possilbe"
                />
                <FormikCheckbox name="interestedInEmployment">
                  {`I’m also interested in employment`}
                </FormikCheckbox>
                {values.interestedInEmployment && (
                  <FormikTextField
                    name="employmentYearlySalary"
                    label="Your annual salary"
                    prefix="€"
                    helpText="Expectations as full-time employee before taxes"
                  />
                )}
              </div>
              <div className="flex justify-between mt-10 md:-mx-8 md:mt-14 md:-mb-8 md:px-10 md:py-4 md:bg-gray-lightest md:border-t md:border-gray-light">
                <Button variant="outline" onClick={onClose}>
                  Close
                </Button>

                <FormikSubmitButton>Update</FormikSubmitButton>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateRateDialog
