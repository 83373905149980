import * as React from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { CrossIcon } from '@upper/icons'
import { classNames } from '@upper/ui'

function Root({
  children,
  ...props
}: DialogPrimitive.DialogProps & {
  children: React.ReactNode
}) {
  return (
    <DialogPrimitive.Root {...props}>
      <DialogPrimitive.Overlay className="fixed inset-0 bg-gray-darkest/75 state-open:animate-fade-in state-closed:animate-fade-out" />
      {children}
    </DialogPrimitive.Root>
  )
}

const Content = React.forwardRef<
  HTMLDivElement,
  DialogPrimitive.DialogContentProps
>(({ children, className, ...props }, forwardedRef) => (
  <DialogPrimitive.Content
    {...props}
    ref={forwardedRef}
    className={classNames(
      'fixed w-[90vw] max-w-xl max-h-[95vh] p-5 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white shadow-xl top-1/2 left-1/2 overflow-y-auto state-open:animate-scale-in sm:p-8',
      className
    )}
  >
    {children}
    <div className="absolute top-0 right-0 flex pt-5">
      <DialogPrimitive.Close className="inline-flex items-center justify-center w-8 h-8 text-white rounded-l-md bg-blue">
        <CrossIcon className="w-4 h-4" />
      </DialogPrimitive.Close>
    </div>
  </DialogPrimitive.Content>
))

export const Dialog = Root
export const DialogTrigger = DialogPrimitive.Trigger
export const DialogContent = Content
export const DialogTitle = DialogPrimitive.Title
export const DialogDescription = DialogPrimitive.Description
export const DialogClose = DialogPrimitive.Close
